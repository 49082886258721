import styled from '@emotion/styled'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { theme } from '../../../styles/theme'
import { surveyScreenUrl } from '../../recommendations/common'
import { cdnUrl } from '../../../common/constants/s3ImageUrls'
import { NextImage } from '../../../common/functionality/NextImage'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { NextLink } from '../../../common/functionality/NextLink'

const Header = styled(NextLink)`
  overflow: hidden;
  height: 462px;
  background-color: ${theme.colors.aegean};
  display: flex;
  justify-content: center;
  align-items: center;

  ${tabletDownMq} {
    flex-direction: column;
    padding: 43px 14px 0;
    min-height: 542px;
  }
`
const HeaderTextContent = styled.div`
  margin-right: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 465px;
  width: 50%;

  ${tabletDownMq} {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }
`
const HeaderTitle = styled.div`
  color: ${theme.colors.grayPorcelain};
  text-align: center;
  font-size: 48px;
  font-weight: 300;

  ${tabletDownMq} {
    font-size: 40px;
    font-weight: 300;
  }
`

const HeaderSubtitle = styled.div`
  color: ${theme.colors.grayPorcelain};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;

  ${tabletDownMq} {
    margin-top: 7px;
    margin-bottom: 12px;
  }
`

const Cta = styled.div`
  display: flex;
  width: 134px;
  padding: 8px 12px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: ${theme.colors.grayPorcelain};
  margin-top: 18px;
`

const CtaText = styled.div`
  color: ${theme.colors.cerulean};
  font-size: 15px;
  font-weight: 600;
  margin-right: 2px;
`

const ImageContainer = styled.div`
  width: 460px;
  height: fill-available;
  position: relative;

  ${tabletDownMq} {
    margin-top: 12px;
  }
`
const imageUrl = cdnUrl('upgrade-labs/dave-asprey-headshot.webp')

export function HomePageBannerUserWithoutGoals() {
  return (
    <Header href={surveyScreenUrl}>
      <HeaderTextContent>
        <HeaderTitle>Achieve your goals.</HeaderTitle>
        <HeaderSubtitle>
          Take our survey to determine your health and wellness goals and receive recommendations on how to make
          progress through tracking, lifestyle adjustments, and supplementation.
        </HeaderSubtitle>
        <Cta>
          <CtaText>Take Survey</CtaText>
          <NavigationArrow orientation="right" color={theme.colors.cerulean} />
        </Cta>
      </HeaderTextContent>
      <ImageContainer>
        <NextImage src={imageUrl} alt="Dave Asprey" fill style={{ objectFit: 'contain', objectPosition: 'bottom' }} />
      </ImageContainer>
    </Header>
  )
}
